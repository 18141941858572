
import axios from 'axios';

const ActivityFacade = {
    async fetchEnums() {
        try {
            const response = await axios.post("https://proyecto-diseno-ol06.onrender.com/api/activity/enums/");
            return response.data;
        } catch (e) {
            console.error(e);
            throw e;
        }
    },

    async fetchProfessors() {
        try {
            const response = await axios.get("https://proyecto-diseno-ol06.onrender.com/api/professors/");
            return response.data;
        } catch (e) {
            console.error(e);
            throw e;
        }
    },

    async createActivity(activityData) {
        try {
            const response = await axios.post('https://proyecto-diseno-ol06.onrender.com/api/activity/', activityData);
            return response.data;
        } catch (e) {
            console.error(e);
            throw e;
        }
    },

    async addActivityToPlan(planId, activityId) {
        try {
            await axios.post('https://proyecto-diseno-ol06.onrender.com/api/plan/addActivity/', {
                id: planId,
                newActivity: activityId
            });
        } catch (e) {
            console.error(e);
            throw e;
        }
    },

    async updateActivity(activityId, activityData) {
        try {
            const response = await axios.patch(`https://proyecto-diseno-ol06.onrender.com/api/activity/${activityId}`, activityData);
            return response.data;
        } catch (e) {
            console.error(e);
            throw e;
        }
    },

    async fetchActivity(activityId) {
        try {
            const response = await axios.get(`https://proyecto-diseno-ol06.onrender.com/api/activity/${activityId}`);
            return response.data;
        } catch (e) {
            console.error(e);
            throw e;
        }
    },

    async fetchImagePath() {
        try {
            const response = await axios.post("https://proyecto-diseno-ol06.onrender.com/api/image/getPath/");
            return response.data;
        } catch (e) {
            console.error(e);
            throw e;
        }
    },

    async removeActivityFromPlan(planId, activityId) {
        try {
            await axios.post("https://proyecto-diseno-ol06.onrender.com/api/plan/removeActivity", { id: planId, activityId: activityId });
        } catch (e) {
            console.error(e);
            throw e;
        }
    },

    async deleteActivity(activityId) {
        try {
            await axios.delete(`https://proyecto-diseno-ol06.onrender.com/api/activity/${activityId}`);
        } catch (e) {
            console.error(e);
            throw e;
        }
    },

    async fetchImage(imageId) {
        try {
            const response = await axios.get(`https://proyecto-diseno-ol06.onrender.com/api/image/${imageId}`);
            return response.data;
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

};

export default ActivityFacade;
