
import axios from 'axios';

const ObservationFacade = {
    async addObservation(observationData) {
        try {
            const response = await axios.post("https://proyecto-diseno-ol06.onrender.com/api/observation/", observationData);
            return response.data;
        } catch (e) {
            console.error(e);
            throw e;
        }
    },

    async fetchObservation(idObservation) {
        try {
            const response = await axios.get(`https://proyecto-diseno-ol06.onrender.com/api/observation/${idObservation}`);
            return response.data;
        } catch (e) {
            console.error(e);
            throw e;
        }
    }
};

export default ObservationFacade;
