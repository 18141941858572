
import axios from 'axios';

const DateFacade = {
    async getSystemDate() {
        try {
            const response = await axios.get('https://proyecto-diseno-ol06.onrender.com/api/systemDate');
            return response.data;
        } catch (e) {
            console.error(e);
            throw e;
        }
    },

    async updateSystemDate(newDate) {
        try {
            const response = await axios.patch('https://proyecto-diseno-ol06.onrender.com/api/systemDate', { date: newDate });
            return response.data;
        } catch (e) {
            console.error(e);
            throw e;
        }
    },

    async notifyObeserver() {
        try {
            console.log('omar gordoooooooooooooooooooooooooo')
            const response = await axios.post('https://proyecto-diseno-ol06.onrender.com/api/observer');
            return response.data;
        } catch (e) {
            console.error(e);
            throw e;
        }
    },

    async resetSystemDate() {
        try {
            const date = new Date();
            const response = await axios.patch('https://proyecto-diseno-ol06.onrender.com/api/systemDate', { date: date });
            return response.data;
        } catch (e) {
            console.error(e);
            throw e;
        }
    }
};

export default DateFacade;
