import FileSelector from "../components/fileSelector";
import React from 'react';

const FilePrueba = () => {
    
    
    return (
        <div className="home">
            <FileSelector/>
        </div>
    );
}

export default FilePrueba;



